import { render, staticRenderFns } from "./About.vue?vue&type=template&id=6e61017c&scoped=true"
import script from "./About.vue?vue&type=script&lang=js"
export * from "./About.vue?vue&type=script&lang=js"
import style0 from "./About.vue?vue&type=style&index=0&id=6e61017c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e61017c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tile: require('/workspace/app/components/tiles/Tile.vue').default,Module: require('/workspace/app/components/layout/Module.vue').default})
