
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    alignment: VueTypes.string.def('left'),
    label: VueTypes.string,
    description: VueTypes.string,
    heading: VueTypes.string,
    visual: VueTypes.arrayOf({
      kind: VueTypes.string,
    }),
    button: VueTypes.array,
  },
};
