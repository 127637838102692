import { render, staticRenderFns } from "./Tile.vue?vue&type=template&id=db2ad9a8&scoped=true"
import script from "./Tile.vue?vue&type=script&lang=js"
export * from "./Tile.vue?vue&type=script&lang=js"
import style0 from "./Tile.vue?vue&type=style&index=0&id=db2ad9a8&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db2ad9a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomLink: require('/workspace/app/components/links/CustomLink.vue').default,Visual: require('/workspace/app/components/media/Visual.vue').default})
