
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    button: VueTypes.objectOf({
      label: VueTypes.string,
      variant: VueTypes.string,
      locationEntry: VueTypes.arrayOf(
        VueTypes.shape({
          uri: VueTypes.string,
        }),
      ),
      locationUrl: VueTypes.string,
      size: VueTypes.string.def('medium'),
    }),
    linkComponent: VueTypes.string.def('PillButton'),
  },
};
