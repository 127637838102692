
import VueTypes from 'vue-types';

import about from '@/gql/queries/globals/about.gql';

export default {
  inheritAttrs: false,
  props: {
    alignment: VueTypes.string.def('left'),
  },
  data() {
    return {
      about: null,
    };
  },
  async fetch() {
    const { aboutData } = await this.$cms.query({
      query: about,
      variables: {
        site: this.$site,
      },
    });
    this.about = aboutData;
  },
};
